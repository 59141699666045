import { Lambda } from '@1mill/lambda'

const isProduction = process.env.NODE_ENV === 'production'

const lambda = new Lambda({
	accessKeyId: isProduction ? 'AKIARDUV5QC5HHO6NDUF' : 'localstack',
	endpoint: isProduction ? null : 'http://localhost:4566/',
	region: isProduction ? 'us-west-1' : 'us-west-1',
	secretAccessKey: isProduction ? 'lXvvhlA5pG1JJBuzKIXafnMd27A6ZsrpnI952TTH' : 'localstack',
})

// TODO: Figure out how to get this to work in production
// console.log(process.env.MILL_LAMBDA_AWS_ACCESS_KEY_ID)
// console.log(process.env.MILL_LAMBDA_AWS_ENDPOINT)
// console.log(process.env.MILL_LAMBDA_AWS_REGION)
// console.log(process.env.MILL_LAMBDA_AWS_SECRET_ACCESS_KEY)
// TODO: ---

export const requestResponse = async ({ cloudevent, functionName }) => lambda.invoke({
	cloudevent,
	functionName,
	invocationType: 'RequestResponse',
})
export const sendEvent = async ({ cloudevent, functionName }) => lambda.invoke({
	cloudevent,
	functionName,
	invocationType: 'Event',
})
